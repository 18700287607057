<template>
  <div class="container">
    <div class="login" @click="to_wx()">
      <div class="login_bt">微信登录{{ message }}</div>
    </div>
  </div>
</template>

<script  setup>
import router from "@/router";
import { onMounted, ref } from "vue";
import { get } from "../axios/api.js";
const message = ref("");

onMounted(() => {
  login();

  get("/wechat/isLogin", {
    activityCode: window.localStorage.getItem("activityCode"),
  })
    .then((response) => {
      if (response.code == "SUCCESS") {
        get("/wechat/isLogin", {
          activityCode: window.localStorage.getItem("activityCode"),
        })
          .then((response) => {
            if (response.code == "SUCCESS") {
              console.log("登录");
              router.push("/home" + window.location.search);
            } else {
              console.log("未登录");
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.log("未登录");
      }
    })
    .catch((error) => {
      console.error(error);
    });
});

const login = () => {
  console.log("当前路由信息", window.location.href);
  console.log("当前路由信息", window.location.search);
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code"); // "John"
  console.log("当前路由信息code=", code);
  if (code != null) get_user(code);
  const activityCode = params.get("activityCode"); // "John"
  console.log("当前路由信息activityCode=", activityCode);
  if (activityCode != null) {
    window.localStorage.setItem("activityCode", activityCode);
  }
};

const get_user = (code) => {
  get("/wechat/userInfo", {
    code: code,
    activityCode: window.localStorage.getItem("activityCode"),
  })
    .then((response) => {
      console.log(response);
      message.value = response;
      if (response.code == "SUCCESS") {
        window.localStorage.setItem("nickname", response.payload.nickname);
        window.localStorage.setItem("headImgUrl", response.payload.headImgUrl);
        // router.push("/home" + window.location.search);
        get("/wechat/isLogin", {
          activityCode: window.localStorage.getItem("activityCode"),
        })
          .then((response) => {
            if (response.code == "SUCCESS") {
              console.log("登录");
              router.push("/home" + window.location.search);
            } else {
              console.log("未登录");
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

const to_wx = () => {
  let url = window.location.href;
  url = `http://mall.zhongdaokj.com/mallAuthCallback/?callback=${encodeURIComponent(
    url
  )}`;
  url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1080fe6eee091065&redirect_uri=${encodeURIComponent(
    url
  )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
  console.log(url);
  window.location.href = url;
};
</script>

<style scoped>
.container {
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  background-image: url("/src/assets/bg.jpg");
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  align-items: center;
  position: absolute;
  background: #fff;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.login_bt {
  width: 200px;
  height: 50px;
  background: #07c160;
  text-align: center;
  align-items: center;
  justify-items: center;
  align-items: center;
  border-radius: 50px;
  line-height: 50px;
  color: #fff;
}
</style>
